@import "config";

// Import only what you need from Bulma
@import "../../node_modules/bulma/sass/utilities/_all.sass";
@import "../../node_modules/bulma/sass/base/_all.sass";
@import "../../node_modules/bulma/sass/elements/_all.sass";
@import "../../node_modules/bulma/sass/form/_all.sass";
@import "../../node_modules/bulma/sass/components/_all.sass";
@import "../../node_modules/bulma/sass/grid/_all.sass";
@import "../../node_modules/bulma/sass/helpers/_all.sass";
@import "../../node_modules/bulma/sass/layout/_all.sass";

@import "../../node_modules/@creativebulma/bulma-collapsible/src/sass/index.sass";

@import "mixins";
@import "navbar";
@import "icons";
@import "background-images";
@import "print";
@import "footer";
@import "content";
@import "jobs";
@import "legal";
