.footer {
  @include BoxShadowHelper(1);
  z-index: 2;
  background-color: $dark;
  a {
    color: $grey-light;
    @include hover-focus {
      color: $primary;
    }
  }
}
