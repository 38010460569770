.bg-wavepattern::after {
  content: "";
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
  background-image: url(/images/wave-pattern.svg) ;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 20%;
}

.bg-wavepattern {

  position: relative;
  z-index: 1;

  .shadow {
    -webkit-filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .3));
    filter: drop-shadow( 2px 2px 1px rgba(0, 0, 0, .3));
    /* Similar syntax to box-shadow */
  }

  .hero-foot.is-dark {
    @include BoxShadowHelper(1);
    background-color: $dark;

    a {
      color: $grey-light;
      @include hover-focus {
        color: $primary;
      }
    }

    a:not(.button):not(.dropdown-item):not(.tag):not(.pagination-link.is-current) {
      color: $grey-light;
      @include hover-focus {
        color: $primary;
      }
    }

  }
}
