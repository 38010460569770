// Box shadow helper
@mixin BoxShadowHelper($level: 1){
  @if $level == 1 {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, .16), 0 2px 10px 0 rgba(0, 0, 0, .12);
  }
  @if $level == 2 {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, .18), 0 4px 15px 0 rgba(0, 0, 0, .15);
  }
}

@mixin hover-focus() {
  &:hover,
  &:focus {
    @content;
  }
}
