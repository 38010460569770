.wrapper {

  background-color: $grey;

  &:not(.hero) {
    padding-top: 8px;
  }

  .breadcrumb-container {
    .breadcrumb {
      font-size: $size-7;
      padding-left: 12px;
    }

    @include desktop {
      .breadcrumb {
        font-size: $size-6;
        padding-left: 0;
      }
    }


    li {
      a {
        &:hover {
          color: darken($green, 10%);
        }
      }
    }
  }

  .content {
    @include BoxShadowHelper(1);
    margin-top: 8px;
    padding: 30px;

    ul {
      margin-left: 20px;
    }
  }

  @include desktop {
    .content {
      font-size: $size-6;
      padding: 60px;

      ul {
        margin-left: 26px;
      }
    }
  }

  .content.has-background-dark {
    h1,h2,h3,h4,h5 {
      color: $green;
      font-family: $family-content-headings;
    }

    a {
      &:not(.button) {
        color: white;

        @include hover-focus {
          color: $green;
        }
      }
    }
  }

  .share-buttons {
    .icon {
      width: 1.2rem;
    }
  }

  .share-buttons-container {
    margin-top: 10px;
  }

  @include desktop {
    .share-buttons-container {
      margin-top: 10px;
    }
  }

}
