.wrapper {
  &.jobs-list {
    .content {
      min-height: 700px;

      @include tablet {
        .container-content {
          text-align: justify;
        }
      }

      .jobs-accordion {
        border-bottom: 1px solid white;
      }

      .card {
        background-color: transparent;
        border-radius: 0;
        border-top: 1px solid white;
        box-shadow: none;
        margin-bottom: 0;

        .card-header {
          box-shadow: none;
        }

        .card-header-title {
          padding-left: 0;
          font-weight: 600;
          font-size: $size-5;
        }

        .card-content {
          color: $grey;
          padding-left: 0;
          padding-top: 0;
        }
      }
    }
  }
}
