@charset "utf-8";

// @see: https://github.com/jgthms/bulma/tree/master/sass/utilities

// Import a Google Font
@import url('https://fonts.googleapis.com/css?family=Nunito:400,700');

// Set your brand colors
$dark: #32323C;
$green: #189466;
$orange: #FFA544;
$yellow: #FFE200;
$blue: #0096FF;
$red:  #FF5E4E;
$grey: #E6E6E6;

$primary: $green;

$family-sans-serif: "Open Sans", sans-serif;
//$family-sans-serif: arial;
//$family-primary: arial;
//$family-secondary: arial;
//$body-family: arial;

// custom var
$family-content-headings: arial;


// Headings
$size-1: 3rem;
$size-2: 2.5rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 1rem;
$size-7: 0.75rem;

// Adjust body font-size
$body-size: 15px;

$link: $dark;
$link-hover: $green;
